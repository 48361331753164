import React, { Component } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import card from "../../images/c5-card.png";
import card2 from "../../images/c5-card2.png";
import sticker from "../../images/c5-sticker2.png";

class Vkyc extends Component {
  anim() {
    if (window !== undefined) {
      const simpleParallax = require("simple-parallax-js");
      var image = document.getElementsByClassName("sp-thumb1");
      new simpleParallax(image, {
        orientation: "up",
        overflow: true,
        maxTransition: 80,
      });
      var image2 = document.getElementsByClassName("sp-thumb2");
      new simpleParallax(image2, {
        orientation: "left",
        overflow: true,
        maxTransition: 60,
        delay: 0,
      });
    }
  }

  render() {
    if (!this.props.data) return <></>;
    return (
      <React.Fragment>
        <Container fluid className="c-pad">
          <Row>
            <Col
              className="order-2 order-md-1 col-md-6 pt-2 d-flex flex-column justify-content-center"
            >
              <div className="c-h1 c5-h1">{this.props.data.header.title}</div>
              <div className="c4-d1">{this.props.data.header.description}</div>
              {/* <div>
                <a href="https://junio.onelink.me/mekW/website" target="_blank">
                  <Button className="c5-button btn-pri">
                    Get your Junio Smart Card
                  </Button>
                </a>
              </div> */}
            </Col>
            <Col className="order-1 order-md-2 p-0 text-end d-none d-md-block">
              <img
                alt=""
                src={this.props.assets[this.props.data.header.image]?.url}
                width="544px"
                height="388px"
              />
            </Col>
            <Col className="col-12 order-1 order-md-2 text-center d-md-none">
              <img
                alt=""
                src={this.props.assets[this.props.data.header.image]?.url}
                width="75%"
                height="auto"
              />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Vkyc;
